.Class__Overview {
  .MuiGrid1 {
    background-image: linear-gradient(
      to right,
      rgb(0, 0, 255),
      rgb(0, 0, 255),
      rgba(255, 255, 255, 0.418)
    );
  }
  .img1 {
    opacity: 0.4;
  }
}
