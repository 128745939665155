.GenerateInvoiceDialog div.MuiDialog-paperWidthSm {
  max-width: none;
}
.GenerateInvoiceDialog .MuiGrid1 {
  min-width: 60vw !important;
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 60px;
  padding-bottom: 60px;
} /*# sourceMappingURL=styles.css.map */
