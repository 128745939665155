@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap");

@import url("https://cdn.syncfusion.com/ej2/material.css");

:root {
  --primary-color: #003d4d;
  --secondary-color: #f8f9fa;
  --tertiary-color: #1d3b91;
  --font-family-1: "Nunito Sans";
  --font-family-2: "Patrick Hand";
  --font-family-3: "Lobster";
  --font-family-4: "Holtwood One SC";

  --color-1: #003d4d;
  --primary-color-2: #f8f9fa;
  --primary-color-3: #36c6d3;
  --primary-color-4: #1d3b91;
  --primary-color-rgb: rgba(29, 95, 111, 0.8);
  --primary-color-hover: #063a5d;
  --primary-font-1: "Nunito Sans";
  --primary-font-2: "Patrick Hand";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.h-min-content {
  height: min-content;
}

.min-h-20 {
  min-height: 20vh;
}

.rotate-45-deg {
  transform: rotate(45deg);
}

.max-width-min-content {
  max-width: min-content !important;
}

.min-h-100vh {
  min-height: 100vh !important;
}

/* color */

/* margin */

/*-------------FONT-SIZE*/
.fs-9-px {
  font-size: 9px;
}

.fs-11-px {
  font-size: 11px !important;
}

.fs-14-px {
  font-size: 14px !important;
}

.fs-15-px {
  font-size: 15px !important;
}

.fs-16-px {
  font-size: 16px !important;
}
/*-------------FONT-SIZE*/

/*-------------FONT-FAMILY*/
.ff-font-family-1 {
  font-family: var(--font-family-1);
}

.ff-font-family-2 {
  font-family: var(--font-family-2);
}

.ff-font-family-3 {
  font-family: var(--font-family-3);
}

.ff-font-family-4 {
  font-family: var(--font-family-4);
}

.ff-primary-font-1 {
  font-family: var(--primary-font-1) !important;
}

.ff-primary-font-2 {
  font-family: var(--primary-font-2) !important;
}
/*-------------FONT-FAMILY*/

.color-color-1 {
  color: var(--color-1) !important;
}

/*--------BACKGROUND-COLOR*/
.bg-color-primary-color {
  background-color: var(--primary-color);
}

.bg-color-secondary-color {
  background-color: var(--secondary-color);
}
/*--------BACKGROUND-COLOR*/
